import React, { FC, useState } from "react";
import { EScreenMode } from "../../model/app/Enums";
import {
	EMessageContentType,
	EMessageType,
	Message,
} from "../../model/data/Message";
import TableWrapper, {
	ITableWrapperProps,
	TableConfig,
} from "../../components/table/TableWrapper";
import { ETableSource } from "../../components/controls/Table";
import Global from "../../utils/Global";
import { EControlType } from "../../components/table/enums/control-type.enum";
import {
	Dialog,
	DialogFooter,
	DialogType,
	IContextualMenuItem,
} from "office-ui-fabric-react";
import { HttpMethods, MenuItems, WebSites } from "../../utils/Defaults";
import StorageHelper from "../../utils/StorageHelper";
import IKeyText from "../../model/app/IKeyText";
import { Button, ButtonToolbar } from "react-bootstrap";
import MessageEditPanel from "./MessageEditPanel";
import { from } from "linq";
import LoaderVf, { ELoaderVfType } from "../../Support/LoaderVf";
import ApiAsync from "../../utils/ApiAsync";
import { IAmResponse } from "../am/Models";

type Props = any;

type State = {
	mode: EScreenMode;
	message: Message;
	messageGroups: IKeyText[];
	showErrorDialog: boolean;
	errorMessage?: string;
};

const MessagesScreen: FC<Props> = (props: Props) => {
	const tableId = "messagesTable";
	const tableRef = React.useRef<TableWrapper>(null);

	const [state, setState] = useState<State>({
		mode: EScreenMode.undefined,
		message: {
			contentTypeId: 0,
			createdBy: "",
			groupId: 0,
			text: "",
			typeId: 0,
			validFrom: new Date(),
			validTo: new Date(),
		},
		messageGroups: StorageHelper.getStructure("messageGroups"),
		showErrorDialog: false,
		errorMessage: undefined,
	});

	const reload = () => {
		if (tableRef) {
			tableRef?.current?.reload();
		}
	};

	const switchMode = (mode: EScreenMode) => {
		return setState((prevState) => {
			return { ...prevState, mode: mode };
		});
	};

	const setMessage = (m: Message) => {
		return setState((prevState) => {
			return { ...prevState, message: m };
		});
	};

	const setErrorMessage = (
		errorMessage: string | undefined,
		showErrorDialog: boolean
	) => {
		return setState((prevState) => {
			return {
				...prevState,
				errorMessage: errorMessage,
				showErrorDialog: showErrorDialog,
			};
		});
	};

	const deleteMessage = async () => {
		switchMode(EScreenMode.loading);
		let baseUrl =
			from<IKeyText>(StorageHelper.getWebsites())
				.toArray()
				.filter((x) => x.key === WebSites.am)[0].text + "message/delete";

		try {
			let response = await ApiAsync.run<IAmResponse>(
				baseUrl,
				HttpMethods.delete,
				state.message
			);
			// debugger;

			// if (response.error && response.error.message) {
			// 	setErrorMessage(response.error.message, true);
			// }

			if (props?.onDismiss) {
				props?.onDismiss();
			}
		} catch (error: any) {
			if (error) {
				setErrorMessage(error.message, true);
			}
		} finally {
			switchMode(EScreenMode.view);
		}

		switchMode(EScreenMode.view);
		reload();
	};

	const renderErrorDialog = (): JSX.Element => {
		return (
			<>
				<Dialog
					hidden={false}
					onDismiss={(e) => {
						switchMode(EScreenMode.view);
					}}
					dialogContentProps={{
						type: DialogType.normal,
						title: state.errorMessage ?? "",
					}}
					modalProps={{
						isBlocking: true,
						containerClassName: "ms-dialogMainOverride",
					}}
				>
					<DialogFooter>
						<Button
							variant="info"
							onClick={() => {
								setErrorMessage(undefined, false);
							}}
						>
							Ok
						</Button>
					</DialogFooter>
				</Dialog>
			</>
		);
	};

	const renderMessageDeleteConfirmDialog = (): JSX.Element => {
		return (
			<>
				<Dialog
					hidden={false}
					onDismiss={(e) => {
						switchMode(EScreenMode.view);
					}}
					dialogContentProps={{
						type: DialogType.normal,
						title: "Sind Sie sicher?",
					}}
					modalProps={{
						isBlocking: true,
						containerClassName: "ms-dialogMainOverride",
					}}
				>
					<DialogFooter>
						<Button variant="danger" onClick={deleteMessage}>
							Ja
						</Button>
						<Button
							variant="secondary"
							onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
								switchMode(EScreenMode.view);
							}}
						>
							Abbrechen
						</Button>
					</DialogFooter>
				</Dialog>
			</>
		);
	};

	const handleContextMenu = (
		message: Message,
		contextualMenuItem: IContextualMenuItem
	) => {
		if (contextualMenuItem.key === MenuItems.edit) {
			switchMode(EScreenMode.edit);
			setMessage(message);
		}

		if (contextualMenuItem.key === MenuItems.delete) {
			switchMode(EScreenMode.delete);
			setMessage(message);
		}
	};

	let contextMenu: IContextualMenuItem[] = [
		{
			key: MenuItems.edit,
			iconProps: { iconName: "Edit" },
			text: "Bearbeiten",
		},
		{
			key: MenuItems.delete,
			iconProps: { iconName: "Delete" },
			text: "Löschen",
		},
	];

	const getTableConfig = (): TableConfig => {
		return TableConfig.createInstance({
			url:
				from<IKeyText>(StorageHelper.getWebsites())
					.toArray()
					.filter((x) => x.key == WebSites.am)[0].text + "message/filter",
			sourceType: ETableSource.dynamic,
			arrayName: "messages",
			sort: [],
			columns: [
				{
					name: "createdBy",
					label: "Erstellt von",
					sortable: true,
				},
				{
					name: "groupId",
					label: "Gruppe",
					sortable: false,
					renderer: (m: Message) => {
						return (
							state?.messageGroups?.filter((x) => x.key === m.groupId)[0]
								?.text || "n/a"
						);
					},
				},
				{
					name: "validFrom",
					label: "Gültig ab",
					sortable: false,
					renderer: (m: Message) => {
						return Global.toGermanDate(m.validFrom);
					},
				},
				{
					name: "validTo",
					label: "Gültig bis",
					sortable: false,
					renderer: (m: Message) => {
						return Global.toGermanDate(m.validTo);
					},
				},
				{
					name: "text",
					label: "Text",
					sortable: true,
				},
				{
					name: "typeId",
					label: "Typ",
					sortable: true,
					renderer: (m: Message) => {
						return Global.getEnumKeyByEnumValue(EMessageType, m.typeId);
					},
				},
				{
					name: "contentTypeId",
					label: "Inhaltstyp",
					sortable: true,
					renderer: (m: Message) => {
						return Global.getEnumKeyByEnumValue(
							EMessageContentType,
							m.contentTypeId
						);
					},
				},
			],
		});
	};

	const getTableProps = (): ITableWrapperProps => {
		return {
			id: tableId.toString(),
			action: handleContextMenu,
			onItemChanged: (item: Message) => {
				setMessage(item);
			},
			model: { needle: "", paging: TableConfig.initPaging() },
			filter: [
				//{ type: EControlType.text, key: "needle", label: "Suche" },
				{
					type: EControlType.dropdown,
					key: "typeId",
					label: "Nachrichtentyp",
					list: Global.getKeyValuesFromEnum(EMessageType),
				},
			],
			contextMenu: contextMenu,
			config: getTableConfig(),
		};
	};

	const handleNewMessage = () => {
		switchMode(EScreenMode.new);
		setMessage({
			contentTypeId: 0,
			createdBy: "",
			groupId: 0,
			text: "",
			typeId: 0,
			validFrom: new Date(),
			validTo: new Date(),
		});
	};

	const renderMain = (): JSX.Element => {
		return (
			<>
				{state.mode == EScreenMode.loading ? (
					<LoaderVf type={ELoaderVfType.full} />
				) : null}
				<ButtonToolbar
					style={{
						marginBottom: "12px",
					}}
				>
					<Button style={{ backgroundColor: "red" }} onClick={handleNewMessage}>
						Neue Nachricht
					</Button>
				</ButtonToolbar>
				<TableWrapper {...getTableProps()} ref={tableRef} />
				<div>
					{[EScreenMode.edit, EScreenMode.new].includes(state?.mode) &&
					state.message ? (
						<MessageEditPanel
							initialMode={state.mode}
							initialModel={state.message}
							onDismiss={() => {
								switchMode(EScreenMode.view);
							}}
							callback={reload}
							onError={setErrorMessage}
						/>
					) : null}
				</div>
				<div>
					{state?.mode === EScreenMode.delete
						? renderMessageDeleteConfirmDialog()
						: null}
				</div>

				<div>{state?.showErrorDialog ? renderErrorDialog() : null}</div>
			</>
		);
	};

	return renderMain();
};

export default MessagesScreen;
