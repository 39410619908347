import React from "react";
import { ETableSource } from "../../components/controls/Table";
import TableWrapper, { ITableWrapperProps, TableConfig } from "../../components/table/TableWrapper";
import { Defaults, MenuItems, Roles, WebSites } from "../../utils/Defaults";
import { from } from 'linq';
import { ContextualMenuItemType, IContextualMenuItem } from "office-ui-fabric-react";
import { EScreenMode } from "../../model/app/Enums";
import Global from "../../utils/Global";
import ErrorScreen from "../error";
import StorageHelper from "../../utils/StorageHelper";
import IKeyText from "../../model/app/IKeyText";
import { EControlType } from "../../components/table/enums/control-type.enum";
import AmGroupEditPanel from "./panels/AmGroupEditPanel";

export interface IDealerGroupScreenState
{
    mode: EScreenMode;
    id: number;
};

export class AmDealerGroupScreen extends React.Component<{}, IDealerGroupScreenState>  {
    private comp: JSX.Element | null = null;
    private current?: any;

    constructor(props: any) {
        super(props);

        this.state = {
            id: 0,
            mode: EScreenMode.view,
        };
    };

    processContextMenu = (item, e) => {
        if(e.key == MenuItems.edit && item.typeId != 1) {
            this.setState({mode: EScreenMode.edit, id: item.id});
        }
    };

    render = () => {
        if (!Global.inAnyRole([Roles.PortalAdministrator, Roles.VB, Roles.VL, Roles.HL, Roles.BO, Roles.TemoDealer, Roles.TemoBackOffice])) {
            return <ErrorScreen data={{ code: 403 }} />
        };

        let cm:IContextualMenuItem[] = [

            { key: MenuItems.edit, iconProps: { iconName: 'Edit' }, text: 'Bearbeiten' }
        ];

        const tc = TableConfig.createInstance({
            url: from<IKeyText>(StorageHelper.getWebsites()).toArray().filter(x => x.key == WebSites.am)[0].text + 'group/filter',
            sourceType: ETableSource.dynamic,
            arrayName: "data",
            sort: [],
            columns: [
                { name: "id", label: "Id", sortable: true },
                { name: "name", label: "Name", sortable: true },
                { name: "applicationId", label: "ApplicationId", sortable: true},
                { name: "typeId", label: "TypeId",
                    renderer: (r, c) => {
                        console.log(r);
                        let text = "Undefined";
                        switch(r.typeId) {
                            case 1:
                                text = "All";
                                break;
                            case 2:
                                text = "List";
                                break;
                        }
                    return <p title={text}>{text}</p>;
                  },
                 },
            ]
        });

        let cfg: ITableWrapperProps = {
            id: "dealerGroups",
            action: this.processContextMenu,
            onItemChanged: (u) => {
                this.current = u;
            },
            model: { needle: "", paging: TableConfig.initPaging() },
            contextMenu: cm,
            filter: [
                { type: EControlType.text, key: "needle", label: "Suche" },
                {
                    type: EControlType.dropdown,
                    key: "typeId",
                    label: "Type",
                    list: [{ key: null, text:"Typ auswählen"}, { key: 1, text:"All"}, { key: 2, text:"List"}]
                }
            ],
            config: tc,
        };

        return <>
            <h1>Gruppen</h1>

            <div className="data">
                <TableWrapper {...cfg}/>

                { this.state.mode == EScreenMode.edit ? <AmGroupEditPanel groupId={this.state.id} onDismiss={()=>this.setState({mode: EScreenMode.view})}/> : null }
            </div>
        </>
    };
};

export default AmDealerGroupScreen;