import React, { FC, useState } from "react";
import { EMessageType, Message } from "../../model/data/Message";
import {
	ActionButton,
	DatePicker,
	Dropdown,
	mergeStyleSets,
	Panel,
	PanelType,
	PrimaryButton,
	TextField,
} from "office-ui-fabric-react";
import { Col, Container, Row } from "react-bootstrap";
import { MainTexts, ValidationMessages } from "../../utils/messages";
import Global from "../../utils/Global";
import { EScreenMode } from "../../model/app/Enums";
import ApiAsync from "../../utils/ApiAsync";
import { IAmResponse } from "../am/Models";
import LoaderVf, { ELoaderVfType } from "../../Support/LoaderVf";
import IKeyText from "../../model/app/IKeyText";
import StorageHelper from "../../utils/StorageHelper";
import {
	EValidationOption,
	IValidationOption,
	Validation,
} from "../../utils/Validation";
import { from } from "linq";
import { WebSites } from "../../utils/Defaults";

type Props = {
	initialMode: EScreenMode;
	initialModel: Message;
	onDismiss?: () => void;
	callback?: () => void;
	onError?: (message: string, showDialog: boolean) => void;
};

type State = {
	model: Message;
	mode: EScreenMode;
	messageGroups: IKeyText[];
	validation: Validation;
	validationErrors: { validation: any[] };
};

const MessageEditPanel: FC<Props> = (props: Props) => {
	const [state, setState] = useState<State>({
		model: props.initialModel,
		mode: props.initialMode,
		messageGroups: StorageHelper.getStructure("messageGroups"),
		validation: new Validation(),
		validationErrors: { validation: [] },
	});

	const styles = mergeStyleSets({
		root: { selectors: { "> *": { marginBottom: 15 } } },
		control: { maxWidth: 300, marginBottom: 15 },
	});

	const setModel = (m: Message) => {
		return setState((prevState) => {
			return { ...prevState, model: m };
		});
	};

	const switchMode = (mode: EScreenMode) => {
		return setState((prevState) => {
			return { ...prevState, mode: mode };
		});
	};

	const resetValidationErrors = () => {
		return setState((prevState) => {
			return {
				...prevState,
				validationErrors: {
					validation: [],
				},
			};
		});
	};

	const setValidationErrors = (validation: any[]) => {
		return setState((prevState) => {
			return {
				...prevState,
				validationErrors: { validation: validation },
			};
		});
	};

	const getValidationOptions = (): IValidationOption[] => {
		var all: IValidationOption[] = [];
		all.push(
			{
				key: "text",
				type: EValidationOption.required,
				message: ValidationMessages.required,
			},
			{
				key: "groupId",
				type: EValidationOption.required,
				message: ValidationMessages.required,
			},
			{
				key: "typeId",
				type: EValidationOption.required,
				message: ValidationMessages.required,
			}
		);
		return all;
	};

	const onSave = async () => {
		resetValidationErrors();
		const modelToBeValidated = { ...state.model, validation: [] };
		if (
			!state.validation.validateModel(
				modelToBeValidated,
				getValidationOptions()
			)
		) {
			const { validation, ...otherProperties } = modelToBeValidated;
			if (validation) {
				setValidationErrors(validation);
			}
			return;
		}

		switchMode(EScreenMode.loading);
		let baseUrl =
			from<IKeyText>(StorageHelper.getWebsites())
				.toArray()
				.filter((x) => x.key === WebSites.am)[0].text + "message/";
		try {
			let result;
			if (props.initialMode && props.initialMode === EScreenMode.new) {
				result = await ApiAsync.post<IAmResponse>(
					`${baseUrl + "Add"}`,
					state.model
				);
			} else {
				result = await ApiAsync.run<IAmResponse>(
					`${baseUrl + "Update"}`,
					"PUT",
					state.model
				);
			}

			if (result.error && result.error.message) {
				if (props.onError) {
					props?.onError(result.error.message, true);
				}
			}

			if (props?.onDismiss) {
				props?.onDismiss();
			}
		} catch (error: any) {
			if (error) {
				return error.message;
			}
		}
		switchMode(props.initialMode);

		if (props.callback !== undefined) {
			props?.callback();
		}
	};

	const onRenderFooterContent = (): JSX.Element => {
		return (
			<>
				<ActionButton
					iconProps={{ iconName: "Cancel" }}
					text="Abbrechen"
					onClick={props.onDismiss}
				/>
				<PrimaryButton text="Speichern" onClick={onSave} />
			</>
		);
	};

	const renderMain = () => {
		return (
			<Container className="ee">
				<Row>
					<Col md={2} xs={2}>
						{"ValidFrom"}
					</Col>
					<Col md={10} xs={10}>
						<DatePicker
							isRequired
							//label="Date required (with label)"
							placeholder="Select a date..."
							ariaLabel="Select a date"
							className={styles.control}
							value={new Date(state.model.validFrom)}
							// DatePicker uses English strings by default. For localized apps, you must override this prop.
							//strings={defaultDatePickerStrings}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2} xs={2}>
						{"ValidTo"}
					</Col>
					<Col md={10} xs={10}>
						<DatePicker
							isRequired
							//label="Date required (with label)"
							placeholder="Select a date..."
							ariaLabel="Select a date"
							className={styles.control}
							value={new Date(state.model.validTo)}
							// DatePicker uses English strings by default. For localized apps, you must override this prop.
							//strings={defaultDatePickerStrings}
						/>
					</Col>
				</Row>
				<Row>
					<Col md={2} xs={2}>
						{"Type"}
					</Col>
					<Col md={10} xs={10}>
						<Dropdown
							required
							errorMessage={Validation.getMessage(
								state.validationErrors,
								"typeId"
							)}
							id={"messageType"}
							options={Global.getKeyValuesFromEnum(EMessageType)}
							selectedKey={state.model.typeId || 0}
							onChange={(e, v) => {
								const newModel = { ...state.model, typeId: Number(v?.key) };
								setModel(newModel);
							}}
						/>
					</Col>
				</Row>

				<Row>
					<Col md={2} xs={2}>
						{"Group"}
					</Col>
					<Col md={10} xs={10}>
						<Dropdown
							required
							errorMessage={Validation.getMessage(
								state.validationErrors,
								"groupId"
							)}
							id={"messageGroup"}
							options={state.messageGroups ?? []}
							selectedKey={state.model.groupId || state.messageGroups[0]?.key}
							onChange={(e, v) => {
								const newModel = {
									...state.model,
									groupId: Number(v?.key),
								};
								setModel(newModel);
							}}
						/>
					</Col>
				</Row>

				<Row>
					<Col md={2} xs={2}>
						{MainTexts.freetext}
					</Col>
					<Col md={10} xs={10}>
						<TextField
							errorMessage={Validation.getMessage(
								state.validationErrors,
								"text"
							)}
							multiline
							id={"messageText"}
							name="text"
							disabled={false}
							value={state.model.text}
							onChange={(e, v) => {
								const newModel = { ...state.model, text: v || "" };
								setModel(newModel);
							}}
						/>
					</Col>
				</Row>
			</Container>
		);
	};
	return (
		<>
			{state.mode == EScreenMode.loading ? (
				<LoaderVf type={ELoaderVfType.full} />
			) : null}
			<Panel
				isOpen={true}
				onDismiss={props.onDismiss}
				type={PanelType.medium}
				headerText="Nachrichteneditor"
				isFooterAtBottom={true}
				onRenderFooterContent={onRenderFooterContent}
			>
				<Container>
					<Row>
						<Col>{renderMain()}</Col>
					</Row>
				</Container>
			</Panel>
		</>
	);
};

export default MessageEditPanel;
