import React from "react";
import { Dropdown, PrimaryButton, TextField } from "office-ui-fabric-react";
import { Col, Container, Row } from "react-bootstrap";
import { EControlType } from "./enums/control-type.enum";
import { ITableFilterItem } from "./interfaces/table-filter-item.interface";
import linq from "linq";
import IKeyText from "../../model/app/IKeyText";
import _ from "lodash";

export interface ITableFilterProps {
  model: any;
  onChanged: any;
  onApply: any;
  items?: ITableFilterItem[];
  action?: any;
}

export interface ITableFilterState {
  mounted?: boolean;
}

export class TableFilter extends React.Component<
  ITableFilterProps,
  ITableFilterState
> {
  static prefix: string = "ef";

  constructor(props: any) {
    super(props);

    this.state = {
      mounted: false,
    };
  }

  componentDidMount(): void {
    this.setState({ mounted: true });
  }

  render() {
    if (!this.state.mounted || !this.props.items) {
      return <></>;
    }

    return (
      <div className={TableFilter.prefix}>
        <Container className="ef">
          <Row>
            <Col md={7} xs={7}></Col>
            <Col md={5} xs={5}>
              <Container>
                {this.renderItems()}
                <Row>
                  <Col md={4} xs={4} className="tar"></Col>
                  <Col md={8} xs={8} className="tal action">
                    {this.props.items.length > 0 ? (
                      <PrimaryButton
                        text="Filtern"
                        onClick={() => {
                          this.props.onApply(this.props.model);
                        }}
                      />
                    ) : null}

                    {this.props.action ? this.props.action : null}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
        <div className="clear"></div>
      </div>
    );
  }

  private renderItems() {
    var ctrl: JSX.Element[] = [];

    this.props.items &&
      this.props.items.map((x, i) => {
        var c = (
          <Row key={"row-" + i}>
            <Col md={4} xs={4} className="tar">
              {x.label}
            </Col>
            <Col md={8} xs={8} className="tal">
              {this.renderControl(x)}
            </Col>
          </Row>
        );

        ctrl.push(c);
      });

    return ctrl;
  }

  private renderControl(ctrl: ITableFilterItem) {
    var next: JSX.Element = <></>;

    if (ctrl.type == EControlType.text) {
      next = (
        <TextField
          id={TableFilter.prefix + "-" + ctrl.key}
          placeholder={""}
          value={this.props.model[ctrl.key]}
          onChange={(e, v) => this.onChange(e, v, undefined)}
        />
      );
    } else if (ctrl.type == EControlType.dropdown) {
      var sel = this.props.model[ctrl.key];

      if (!sel) {
        sel = ctrl.list![0].key;
      }

      next = (
        <Dropdown
          id={TableFilter.prefix + "-" + ctrl.key}
          options={linq
            .from<IKeyText>(ctrl.list!)
            .select((x) => {
              return { key: x.key, text: x.text };
            })
            .toArray()}
          selectedKey={sel}
          onChange={(e, v) => {
            this.onChange(e, v, ctrl.key);
          }}
        />
      );
    }

    return next;
  }

  private onChange = (e, v, k) => {
    var key = k;

    //dropdown
    if (key) {
      this.props.model[key] = v.key;
    } else {
      //text
      key = e.target.id.replace(TableFilter.prefix + "-", "");
      this.props.model[key] = e.target.value;
    }

    this.props.onChanged(this.props.model);
  };
}

export default TableFilter;
