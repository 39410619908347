import { IPaging } from "../../components/table/Models";
import { IApiError } from "../../utils/ApiAsync";
import Global from "../../utils/Global";

export enum EUserType {
    dealer = 1,
    vodafone = 2,
    admin = 3
}

export interface IAmPasswordChange {
    password: string;
    repeat: string;
};

export class AmHelper {
    static getNewUser(): IAmUser {
        var u: IAmUser = JSON.parse(JSON.stringify({ id: Global.getEmptyGuid(), void: '', managerId: '', controllerId: '', username: '', statusId: 1, positionId: 7, typeId: 1, salutationId: 1, roles: [], dealer: {} }));

        return u;
    };
};

export interface IShopOrder extends IContainsError {
    id: number;
    void: string;

    statusId: number;
    text: string;

    orderDate: Date;

    account: IAmAccount;
    address: IAddress;
    products: IShopOrderProduct[];
};

export interface IShopProduct {

};

export interface IShopOrderProduct {
    productId: number;
    productText: string;
    amount: number;
    price: number;
    total: number;
    name: string;
};

export interface IAmUserChangePasswordRequest extends IContainsError {
    passwordNew: string;
    passwordRepeat: string;
    username: string;
    password: string;
};

export interface IAmProfilePatchDTORequest extends IContainsError {
    username: string;
    firstname: string;
    surname: string;
    mobile: string;
    email: string;
};

export interface IAddress {
    zip: string;
    street: string;
    nr: string;
    city: string;
};

export interface IAmUser extends IContainsError {
    id: string;
    username: string;
    firstname: string;
    surname: string;
    salutationId: number;
    email: string;
    mobile: string;
    statusId: number;
    positionId: number;
    typeId: number;
    managerId: string;

    controllerId: string;
    void: string;

    dealer: IAmAccount;

    roles: string[];

    error?: IApiError;

    autoActivate?: boolean;

    password: string;
};

export interface IContainsError {
    error?: IApiError;
};

export interface IAmAccount extends IContainsError {
    address: IAddress;

    booking: IBooking;
    properties: IBooking;

    id: string;
    vpkn: string;
    fnvoid: string;
    unitySalesId: string;
    name: string;
    statusCode: string;
    regionId: string;
    level?: number;
    sfForm: string;
    sfType: string;

    parents?: IAmAccount[];
    /**
   * this will hold info from structure import initial booking possibilities info
   */
    vAccountBooking: IBooking;
};

export interface ILogHistoryAccountProperties extends IAmAccount, IBooking {

}

export interface IAmAccountMTan {
    id: string;
    name: string;
    address: string;

    level?: number;

    level1: string;
    level2: string;

    vbName: string;
    vlName: string;

    tanAuthExDateSet?: Date;
    tanAuthExCustomer?: boolean;
};

export interface IAmUserSub extends IContainsError {
    id: string;
    from: Date;
    to: Date;
    source: IAmUser;
    target: IAmUser;
    sourceId: string;
    targetId: string;

    isNew?: boolean;
}

export interface IAmUserSubRequest {
    id: string;
    from: string;
    to: string;
    sourceId: string;
    targetId: string;
}

export interface IAddress {
    zip: string;
    city: string;
    street: string;
    nr: string;
};

export interface IBooking {
    bookOtelo?: boolean;
    bookVodafone?: boolean;
    bookCable?: boolean;
    bookDsl?: boolean;
    bookCallya?: boolean;
    bookTivano?: boolean;

    bookBusiness?: boolean;
    bookHardwarelistBNTmnpDCS?: boolean;
    bookUnity?: boolean;

    bookManualDate?: any;

    buvLimit?: boolean;
    digitalSignAllowed?: boolean;
    fraudCounter?: number;
    freeTextCMallowed?: boolean;
    kabelOrderState?: boolean;
    nbaAllowed?: boolean;
    parkingEnabled?: boolean;
    tanAuthExCustomer?: boolean;
    vfVvlHardware?: boolean;
    created?: Date;
    comment?: string;
    changedByUsername?: string;
    bookVodafoneHardware?:boolean;
};

export interface IAmDefaultModel {
    needle: string;
    paging?: IPaging;
};

export interface IUserFilterModel extends IAmDefaultModel {
    statusId: number;
};

export interface IAmResponse {
    paging: IPaging;
    error?: IApiError;
};

export interface IAmGroupMembership extends IContainsError {
    id?: number;
    groupId?: number;
    void?: string;
}